/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import Cookies from "js-cookie";

export class HttpAPI {
  public static readonly baseUrl =
    (typeof window === "undefined" ? process.env["SERVER_URL"] : "") +
    "/api/web";

  public static async head(
    token: string | null,
    url: string,
    headers: Record<string, string> = {}
  ) {
    try {
      url = [this.baseUrl.replace(/\/$/, ""), url.replace(/^\//, "")].join("/");

      return await this.handleFetchResponse(
        await fetch(url, {
          method: "head",
          headers: this.injectToken(token, headers || {})
        }),
        token
      );
    } catch (e) {
      const response = e as Response;
      throw response.status;
    }
  }

  public static async get(
    token: string | null,
    url: string,
    headers: Record<string, string> = {}
  ) {
    try {
      url = [this.baseUrl.replace(/\/$/, ""), url.replace(/^\//, "")].join("/");

      return await this.handleFetchResponse(
        await fetch(url, {
          cache: "no-store", // Opting out of Data Caching
          method: "GET",
          headers: this.injectToken(token, headers || {})
        }),
        token
      );
    } catch (e) {
      const response = e as Response;
      throw response.status;
    }
  }

  public static async post(
    token: string | null,
    url: string,
    data: object = {},
    headers: Record<string, string> = {}
  ) {
    headers = { "Content-Type": "application/json", ...headers };
    const isFormData = headers["Content-Type"].includes("multipart/form-data");

    if (isFormData) {
      // https://stackoverflow.com/questions/67996124/unable-to-load-file-due-to-multipart-boundary-not-found/67996268#67996268
      delete headers["Content-Type"];
    }

    try {
      url = [this.baseUrl.replace(/\/$/, ""), url.replace(/^\//, "")].join("/");

      return await this.handleFetchResponse(
        await fetch(url, {
          method: "POST",
          headers: this.injectToken(token, headers),
          body: isFormData ? (data as FormData) : JSON.stringify(data)
        }),
        token
      );
    } catch (e) {
      const response = e as Response;
      throw response.status;
    }
  }

  public static async patch(
    token: string | null,
    url: string,
    data: object = {},
    headers: Record<string, string> = {}
  ) {
    try {
      url = [this.baseUrl.replace(/\/$/, ""), url.replace(/^\//, "")].join("/");

      return await this.handleFetchResponse(
        await fetch(url, {
          method: "PATCH",
          headers: this.injectToken(token, {
            ...headers,
            "Content-Type": "application/json"
          }),
          body: JSON.stringify(data)
        }),
        token
      );
    } catch (e) {
      const response = e as Response;
      throw response.status;
    }
  }

  public static async delete(
    token: string | null,
    url: string,
    headers: Record<string, string> = {}
  ) {
    try {
      url = [this.baseUrl.replace(/\/$/, ""), url.replace(/^\//, "")].join("/");

      return await this.handleFetchResponse(
        await fetch(url, {
          method: "DELETE",
          headers: this.injectToken(token, headers || {})
        }),
        token
      );
    } catch (e) {
      const response = e as Response;
      throw response.status;
    }
  }

  public static async getStream(token: string | null, url: string) {
    url = [this.baseUrl.replace(/\/$/, ""), url.replace(/^\//, "")].join("/");
    try {
      return await fetch(url, {
        method: "get",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      });
    } catch (e) {
      const response = e as Response;
      throw response.status;
    }
  }

  public static async postStream(
    token: string | null,
    url: string,
    data: object
  ) {
    url = [this.baseUrl.replace(/\/$/, ""), url.replace(/^\//, "")].join("/");
    try {
      return await fetch(url, {
        method: "post",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      });
    } catch (e) {
      const response = e as Response;
      throw response.status;
    }
  }

  private static handleFetchResponse(
    response: Response,
    requestToken: string | null
  ) {
    if (401 === response.status && "undefined" !== typeof window) {
      Cookies.remove("access-token");
      if (requestToken) {
        // Redirect to sign in page only if request token exists.
        // If request token doesn't exist, it is a request from sign up/sign in/forget page.
        location.href = `/signin?back=${encodeURIComponent(location.href)}`;
      }
    }
    if (500 === response.status && "undefined" !== typeof window) {
      throw response.status;
    }
    if (!response.ok) throw response;
    const accessToken = response.headers.get("x-access-token");
    const isJson = response.headers
      .get("content-type")
      ?.includes("application/json");
    if (!accessToken) return isJson ? response.json() : response.text();
    Cookies.set("access-token", accessToken);
    return isJson ? response.json() : response.text();
  }

  private static injectToken(
    token: string | null,
    headers: Record<string, string>
  ): Record<string, string> {
    if (!token) return headers;
    headers = headers || {};
    headers["Authorization"] = `Bearer ${token}`;
    return headers;
  }
}
