/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef, useState } from "react";
import AnswerIcon from "../icons/Answer";
import ChatIcon from "../icons/Chat";
import ImageIcon from "../icons/Image";
import RelatedIcon from "../icons/Related";
import RightIcon from "../icons/Right";
import SearchIcon from "../icons/Search";
import SourceIcon from "../icons/Source";
import "./Chat.css";
import PlusIcon from "../icons/Plus";
import CloseIcon from "../icons/Close";
import LeftIcon from "../icons/Left";
import { useParams } from "react-router-dom";
import { ChatAPI } from "../api/chat.api";
import Markdown from "react-markdown";
import getRootDomain from "get-root-domain";
import MoreIcon from "../icons/More";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Common from "./Common";
import { Container, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import rehypeExternalLinks from "rehype-external-links";

export default function Chat() {
  const [sessions, setSessions] = useState<any[]>();
  const [question, setQuestion] = useState("");
  const [showImages, setShowImages] = useState(false);
  const [gallery, setGallery] = useState<any[]>([]);
  const [galleryIndex, setGalleryIndex] = useState(0);
  const [sources, setSources] = useState<Record<number, string>>({});
  const [answers, setAnswers] = useState<Record<string, string>>({});
  const [sourcesLimit, setSourcesLimit] = useState(3);
  const [showSessions, setShowSessions] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const { sessionId } = useParams();

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    const sessions = await ChatAPI.getSession(sessionId!);
    setSessions(sessions);
    const sources: Record<number, string> = {};
    sessions.forEach((_: never, i: number) => {
      sources[i] = "tavily";
    });
    setSources(sources);
    setShowSessions([sessions.length - 1]);
  };

  const loadingLock = useRef<string[]>([]);
  useEffect(() => {
    if (!sessions?.length) return;
    setSourcesLimit(3);
    showAnswers();
  }, [sources, sessions]);

  useEffect(() => {
    showAnswers();
  }, [showSessions]);

  const showAnswers = async () => {
    for (const showSession of showSessions) {
      await loadAnswer(showSession);
    }
  };

  const retrieveResults = async (index: number) => {
    await ChatAPI.retrieveResults(sessionId, index);
    const sessions = await ChatAPI.getSession(sessionId!);
    setSessions(sessions);
    return sessions;
  };

  const loadAnswer = async (index: number) => {
    if (loadingLock.current.includes(`${sources[index]}${index}`)) return;
    if (`${sources[index]}${index}` in answers) return;
    loadingLock.current.push(`${sources[index]}${index}`);

    if (!("tavily" in sessions?.[index]?.results)) {
      await retrieveResults(index);
    }

    setIsLoading(false);
    const newAnswers = { ...answers, [`${sources[index]}${index}`]: "" };
    setAnswers(newAnswers);
    const es = await ChatAPI.getAnswerBySource(
      sessionId!,
      index,
      sources[index]
    );
    es.addEventListener("message", (m) => {
      newAnswers[`${sources[index]}${index}`] += m.data;
      if ("#END" === m.data) {
        // loadingLock.current = false;
        es.close();
        if (!sessions?.[index]?.related?.length) {
          ChatAPI.getSession(sessionId!).then(setSessions);
        }
        return;
      }
      setAnswers({ ...newAnswers });
    });
  };

  const showImage = (images: any[], index: number) => {
    setGalleryIndex(index);
    setGallery(images);
    setShowImages(true);
  };

  const submit = async (q?: string) => {
    q = q || question || "";
    setQuestion("");
    if (!q) {
      return;
    }
    setIsLoading(true);
    await ChatAPI.appendSession(sessionId!, q);
    load();
  };

  return (
    <Container className="chat-page">
      <Common />
      <div className="image-viewer-container" hidden={!showImages}>
        <div className="image-viewer">
          <div className="header">
            <div className="title">
              <div className="main">图片结果</div>
              <div className="sub">中华人民共和国民法典 不当得利 条文</div>
            </div>
            <div className="close" onClick={() => setShowImages(false)}>
              <CloseIcon />
            </div>
          </div>
          <div className="image-viewport">
            <ul
              className="images"
              style={{ left: `calc(-100% * ${galleryIndex})` }}
            >
              {gallery.map((v) => (
                <li>
                  <img src={v.url} alt="" />
                </li>
              ))}
            </ul>
          </div>
          <div className="actions">
            <div
              className="button"
              onClick={() => setGalleryIndex(Math.max(galleryIndex - 1, 0))}
            >
              <LeftIcon />
            </div>
            <div
              className="button"
              onClick={() =>
                setGalleryIndex(Math.min(galleryIndex + 1, gallery.length - 1))
              }
            >
              <RightIcon />
            </div>
          </div>
          <div className="indicator">
            {galleryIndex + 1} of {gallery.length}
          </div>
        </div>
      </div>
      {sessions?.map((session, i) => (
        <div className="block" hidden={!showSessions.includes(i)}>
          <h4 className="title">{session?.question}</h4>
          <div
            className={`toggle ${!showSessions.includes(i) && "fold"}`}
            onClick={() =>
              setShowSessions(
                showSessions.includes(i)
                  ? showSessions.filter((v) => v !== i)
                  : [...showSessions, i]
              )
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              stroke-linejoin="round"
            >
              <path d="m6 9 6 6 6-6"></path>
            </svg>
          </div>
          <div className="agent" hidden={!("tavily" in session["results"])}>
            <div className="tab-switch">
              <div
                className={`label ${"tavily" === sources[i] && "active"}`}
                onClick={() => setSources({ ...sources, [i]: "tavily" })}
              >
                <SearchIcon />
                基于全网
              </div>
              <div
                className={`label ${"rag" === sources[i] && "active"}`}
                onClick={() => setSources({ ...sources, [i]: "rag" })}
              >
                <SearchIcon />
                基于知识库
              </div>
            </div>
            <div className="result">
              <h6
                className="legend"
                hidden={session?.results?.[sources[i]]?.images.length === 0}
              >
                <ImageIcon />
                图片结果
              </h6>
              <ul
                className="images"
                hidden={session?.results?.[sources[i]]?.images.length === 0}
              >
                {session?.results?.[sources[i]]?.images
                  ?.slice(0, 3)
                  ?.map((s: any, j: number) => {
                    return (
                      <li>
                        <a
                          href={"##"}
                          className="link"
                          onClick={() =>
                            showImage(session?.results?.[sources[i]]?.images, j)
                          }
                        >
                          <img src={s.url} alt="" />
                        </a>
                      </li>
                    );
                  })}
                <li hidden={session?.results?.[sources[i]]?.images.length < 4}>
                  <a
                    href={"##"}
                    className="link"
                    onClick={() =>
                      showImage(session?.results?.[sources[i]]?.images, 3)
                    }
                  >
                    <img
                      src={session?.results?.[sources[i]]?.images[3]?.url}
                      alt=""
                    />
                    <div
                      className="more"
                      hidden={
                        session?.results?.[sources[i]]?.images.length <= 4
                      }
                    >
                      <MoreIcon />
                    </div>
                  </a>
                </li>
              </ul>
              <h6 className="legend">
                <SourceIcon />
                来源
              </h6>
              <div className="sources">
                {session?.results?.[sources[i]]?.results
                  ?.slice(0, sourcesLimit)
                  ?.map((s: any, j: number) => {
                    return (
                      <li>
                        <a
                          href={s.url}
                          className="link"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <div className="title">{s.title}</div>
                          <div className="site">
                            <img
                              src={`https://www.google.com/s2/favicons?domain=${
                                new URL(s.url).hostname
                              }`}
                              width={12}
                              height={12}
                              alt=""
                            />
                            {getRootDomain(s.url)} - {j}
                          </div>
                        </a>
                      </li>
                    );
                  })}
                <li
                  hidden={
                    session?.results?.[sources[i]]?.results?.length <= 3 ||
                    sourcesLimit === Infinity
                  }
                >
                  <a
                    href="##"
                    className="link center"
                    onClick={() => setSourcesLimit(Infinity)}
                  >
                    View {session?.results?.[sources[i]]?.results.length - 3}{" "}
                    more
                  </a>
                </li>
              </div>
            </div>
            <h6 className="legend">
              <AnswerIcon />
              回答
            </h6>
            <div className="answer">
              {answers[`${sources[i]}${i}`] ? (
                <Markdown
                  className="content"
                  rehypePlugins={[[rehypeExternalLinks, { target: "_blank" }]]}
                >
                  {answers[`${sources[i]}${i}`]}
                </Markdown>
              ) : (
                <Skeleton />
              )}
            </div>
            <h6 className="legend">
              <RelatedIcon />
              相关问题
            </h6>
            <div className="related">
              {session.related?.map((related: string) => (
                <li>
                  <RightIcon />
                  <a href="##" className="link" onClick={() => submit(related)}>
                    {related}
                  </a>
                </li>
              ))}
            </div>
          </div>
        </div>
      ))}
      <div className="loading-container" hidden={!isLoading}>
        <Skeleton />
      </div>
      <div className="chat-next" hidden={isLoading}>
        <h6 className="legend">
          <ChatIcon />
          继续提问
        </h6>
      </div>
      <div className="search-container" hidden={isLoading}>
        <div className="search-box">
          <Form.Control
            type="text"
            placeholder="请输入您的问题"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                submit();
              }
            }}
          />
          <div className="button" onClick={() => submit()}>
            <RightIcon />
          </div>
        </div>
      </div>
      <a href="/" className="back">
        <div className="text">New</div>
        <PlusIcon />
      </a>
    </Container>
  );
}
