/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { useState } from "react";
import "./Home.css";
import RightIcon from "../icons/Right";
import { ChatAPI } from "../api/chat.api";
import "react-loading-skeleton/dist/skeleton.css";
import Common from "./Common";
import "bootstrap/dist/css/bootstrap.css";
import { Container, Form } from "react-bootstrap";

export default function App() {
  const [question, setQuestion] = useState("");
  // const [isLoading, setIsLoading] = useState(false);

  const submit = async (q?: string) => {
    q = q || question || "";
    if (!q) {
      return;
    }
    // setQuestion(q);
    // setIsLoading(true);
    const sessionId = await ChatAPI.createSession(q);
    window.location.href = `/session/${sessionId}`;
  };
  return (
    <Container className="home-page-container">
      <Common />
      {/* <div className="home-page-loading container" hidden={!isLoading}>
        <h4 className="title">{question}</h4>
        <Skeleton />
      </div> */}
      <div className="home-page">
        <h1 className="title">
          <span className="brand">LawSight</span>
          <span style={{ whiteSpace: "nowrap" }}>
            可靠的法律
            <span className="underline">咨询与检索</span>
            智能助手
          </span>
        </h1>
        <div className="search-container">
          <div className="search-box">
            <Form.Control
              placeholder="请输入您的问题"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  submit();
                }
              }}
            />
            {/* <input
              type="text"
              placeholder="请输入您的问题"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  submit();
                }
              }}
            /> */}
            <div className="button">
              <RightIcon />
            </div>
          </div>
        </div>
        <ul className="list-group hints">
          <li>
            <RightIcon />
            <a
              href="##"
              className="link"
              onClick={() =>
                submit(
                  "《中华人民共和国民法典》中关于不当得利的条文规定有哪些？"
                )
              }
            >
              《中华人民共和国民法典》中关于不当得利的条文规定有哪些？
            </a>
          </li>
          <li>
            <RightIcon />
            <a
              href="##"
              className="link"
              onClick={() => submit("分期偿还的债务，如何认定保证期间？")}
            >
              分期偿还的债务，如何认定保证期间？
            </a>
          </li>
          <li>
            <RightIcon />
            <a
              href="##"
              className="link"
              onClick={() => submit("平台组织线上开盲盒是否构成赌博行为？")}
            >
              平台组织线上开盲盒是否构成赌博行为？
            </a>
          </li>
          <li>
            <RightIcon />
            <a
              href="##"
              className="link"
              onClick={() =>
                submit("国有企业转让资产，交易价款是否允许分期支付？")
              }
            >
              国有企业转让资产，交易价款是否允许分期支付？
            </a>
          </li>
          <li>
            <RightIcon />
            <a
              href="##"
              className="link"
              onClick={() =>
                submit(
                  "如果一个邮政工作人员私自开拆了一个邮件，但只是为了查看其中的收件人地址，而并未泄露邮件内容，是否构成什么罪行？"
                )
              }
            >
              如果一个邮政工作人员私自开拆了一个邮件，但只是为了查看其中的收件人地址，而并未泄露邮件内容，是否构成什么罪行？
            </a>
          </li>
        </ul>
      </div>
    </Container>
  );
}
