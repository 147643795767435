/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import Cookies from "js-cookie";
import { HttpAPI } from "./http.api";

export class ChatAPI {
  static retrieveResults(sessionId: string | undefined, index: number) {
    return HttpAPI.get(
      Cookies.get("access-token") || "",
      `/chat/by-session/${sessionId}/${index}/results`
    );
  }

  static async createSession(question: string): Promise<string> {
    return HttpAPI.post(Cookies.get("access-token") || "", "/chat/session", {
      question
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static async getSession(sessionId: string): Promise<any> {
    return HttpAPI.get(
      Cookies.get("access-token") || "",
      `/chat/by-session/${sessionId}`
    );
  }

  static async getAnswerBySource(
    sessionId: string,
    index: number,
    source: string
  ) {
    return new EventSource(
      `/api/web/chat/by-session/${sessionId}/${index}/${source}`,
      {}
    );
  }

  static async appendSession(sessionId: string, question: string) {
    return (
      await fetch(`/api/web/chat/by-session/${sessionId}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        method: "PATCH",
        body: JSON.stringify({ question })
      })
    ).text();
  }
}
