/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import "./Common.css";

export default function Common() {
  return (
    <>
      <div className="common">
        <div className="home">
          <a href="/">
            <svg
              fill="currentColor"
              viewBox="0 0 256 256"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="128" cy="128" r="128" fill="#ED2224"></circle>
              <circle cx="102" cy="128" r="18" fill="white"></circle>
              <circle cx="154" cy="128" r="18" fill="white"></circle>
            </svg>
          </a>
        </div>
        <div className="copyright">
          &copy;{new Date().getFullYear()} txz.tech
        </div>
      </div>
    </>
  );
}
