/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpAPI } from "./http.api";
import Cookies from "js-cookie";
import { decode } from "js-base64";

export class AuthAPI {
  static getLocalUser(): any | null {
    const token = Cookies.get("access-token");
    if (!token) return null;
    return JSON.parse(decode(token.split(".")[1])).webUser;
  }

  static async signIn(
    email: string,
    password: string,
    shouldRemember: boolean
  ) {
    const metadata = await HttpAPI.get(
      null,
      `auth/signin/${encodeURIComponent(email)}/${encodeURIComponent(password)}`
    );

    if (shouldRemember) {
      Cookies.set("access-token", Cookies.get("access-token") || "", {
        expires: 365
      });
    }

    return metadata;
  }

  static async signUp(email: string, password: string) {
    return HttpAPI.post(null, "auth/signup", { email, password });
  }

  static changePassword(originPassword: string, password: string) {
    return HttpAPI.patch(Cookies.get("access-token") || "", "auth/password", {
      originPassword,
      password
    });
  }

  static async signOff() {
    await HttpAPI.delete(Cookies.get("access-token") || "", "auth");
    Cookies.remove("access-token");
    Cookies.remove("ignore-study-statement");
    Cookies.remove("referral");
  }
}
