/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { Button, Container, Form, InputGroup } from "react-bootstrap";
import "./SignUp.css";
import Common from "./Common";
import { useState } from "react";
import { Eye, EyeSlashFill } from "react-bootstrap-icons";
import { AuthAPI } from "../api/auth.api";

export default function SignUp() {
  const [showPass, setShowPass] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const togglePassword = () => {
    setShowPass((prev) => !prev);
  };

  const submit = async () => {
    if (!email || !password) return;
    if (password !== confirmPassword) {
      return;
    }

    await AuthAPI.signUp(email, password);
    window.location.href = "/";
  };

  return (
    <div className="signup-page">
      <Common />
      <Container className="form">
        <Form>
          <h1 className="brand">LawSight</h1>
          <h2>Sign up</h2>
          <div className="form-group">
            <Form.Label>Email</Form.Label>
            <InputGroup className="mb-3 bordered">
              <Form.Control
                type="email"
                className="form-control"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </InputGroup>
          </div>
          <div className="form-group">
            <Form.Label>Password</Form.Label>
            <InputGroup className="mb-3 bordered">
              <Form.Control
                type={showPass ? "text" : "password"}
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <InputGroup.Text onClick={togglePassword} className="toggler">
                {showPass ? <Eye /> : <EyeSlashFill />}
              </InputGroup.Text>
            </InputGroup>
          </div>
          <div className="form-group">
            <Form.Label>Confirm Password</Form.Label>
            <InputGroup className="mb-3 bordered">
              <Form.Control
                type={showPass ? "text" : "password"}
                placeholder="Confirm your password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
              <InputGroup.Text onClick={togglePassword} className="toggler">
                {showPass ? <Eye /> : <EyeSlashFill />}
              </InputGroup.Text>
            </InputGroup>
          </div>
          <Button className="button" onClick={submit}>
            Register
          </Button>
          <div className="signin">
            Already have an account?{" "}
            <a href="/signin" className="link">
              Sign In
            </a>
          </div>
        </Form>
      </Container>
    </div>
  );
}
